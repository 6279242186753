"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { VariantProps } from "class-variance-authority";
import { getCookie, hasCookie } from "cookies-next";
import {
	Bell,
	BellOff,
	Bookmark,
	BookmarkIcon,
	Info,
	PlusCircle,
	XCircle,
	XIcon,
} from "lucide-react";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import * as React from "react";
import { useForm } from "react-hook-form";
import { HiBookmark } from "react-icons/hi2";
import useSWR from "swr";
import { Collection, Page, SavedPage } from "types";
import { useDebouncedCallback } from "use-debounce";
import { z } from "zod";

import revalidateByTag from "@/app/actions/revalidate/actions";
import { ClientOnly } from "@/components/client-only";
import parse from "@/components/html-parser";
import { Spinner } from "@/components/icons/spinner";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants, ToggleButton } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { toast } from "@/components/ui/use-toast";
import Update from "@/components/update";
import { useUser } from "@/lib/hooks/auth";
import { fetchWrapper, swrFetcher } from "@/lib/hooks/fetch-client";
import { cn } from "@/lib/utils";

interface AddCollectionDialogProps extends React.HTMLAttributes<HTMLDivElement> {}

const AddCollectionDialog = ({ children }: AddCollectionDialogProps) => {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const token = hasCookie("token") ? getCookie("token") : "";
	const { mutate } = useSWR(token ? ["/v1/members/collections/"] : null, swrFetcher);

	const CollectionFormSchema = z.object({
		collection_name: z.string(),
	});

	const collectionForm = useForm<z.infer<typeof CollectionFormSchema>>({
		resolver: zodResolver(CollectionFormSchema),
		defaultValues: {
			collection_name: "",
		},
	});

	const onSubmit = async (values: z.infer<typeof CollectionFormSchema>) => {
		setLoading(true);
		try {
			await fetchWrapper("/v1/members/collections", {
				method: "POST",
				body: JSON.stringify({ name: values.collection_name }),
			});
			mutate();
		} catch (error) {
			toast({
				title: "Error!",
				description: error.message,
				action: (
					<ToastAction altText="Try again" onClick={() => onSubmit(values)}>
						Try again
					</ToastAction>
				),
				variant: "error",
			});
		} finally {
			setLoading(false);
			setOpen(false);
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>New collection</DialogTitle>
					<VisuallyHidden>
						<DialogDescription>New collection</DialogDescription>
					</VisuallyHidden>
				</DialogHeader>
				<Form {...collectionForm}>
					<form onSubmit={collectionForm.handleSubmit(onSubmit)}>
						<FormField
							control={collectionForm.control}
							name="collection_name"
							render={({ field }) => (
								<FormItem className="flex flex-col space-y-2">
									<FormLabel className="text-start">Collection name</FormLabel>
									<FormControl>
										<Input className="col-span-3" {...field} />
									</FormControl>
								</FormItem>
							)}
						/>
					</form>
				</Form>
				<DialogFooter>
					<Button
						type="submit"
						disabled={loading}
						aria-disabled={loading}
						onClick={() => onSubmit(collectionForm.getValues())}
					>
						{loading && <Spinner color="gray" size="4" />}
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

const CollectionItem = ({
	collection,
	pageId,
	mutate,
}: {
	collection: Collection;
	pageId: string;
	mutate: () => void;
}) => {
	const isFollowing = collection?.following.some((item: { id: string }) => item.id === pageId);
	const [loading, setLoading] = React.useState(false);

	const addPageToCollection = async (collectionId: string) => {
		setLoading(true);
		try {
			await fetchWrapper(`/v1/members/collections/${collectionId}/add`, {
				method: "POST",
				body: JSON.stringify({ follow_id: pageId }),
			});
		} catch (error) {
			toast({
				title: "Error!",
				description: error.message,
				action: (
					<ToastAction altText="Try again" onClick={() => addPageToCollection(collectionId)}>
						Try again
					</ToastAction>
				),
				variant: "error",
			});
		} finally {
			mutate();
			setLoading(false);
			revalidateByTag("saved-pages");
		}
	};

	const removePageFromCollection = async (collectionId: string) => {
		setLoading(true);
		try {
			await fetchWrapper(`/v1/members/collections/${collectionId}/delete/${pageId}`, {
				method: "DELETE",
			});
		} catch (error) {
			toast({
				title: "Error!",
				description: error.message,
				action: (
					<ToastAction altText="Try again" onClick={() => removePageFromCollection(collectionId)}>
						Try again
					</ToastAction>
				),
				variant: "error",
			});
		} finally {
			mutate();
			setLoading(false);
			revalidateByTag("saved-pages");
		}
	};

	return (
		<div className="flex items-center justify-between gap-2">
			<div className="">{collection.name}</div>
			{isFollowing ? (
				<ToggleButton
					state="off"
					onClick={() => removePageFromCollection(collection.id)}
					loading={loading}
					iconSize={20}
					isCheck={true}
					title="Remove page from collection"
				/>
			) : (
				<ToggleButton
					state="on"
					onClick={() => addPageToCollection(collection.id)}
					loading={loading}
					iconSize={20}
					title="Add page to collection"
				/>
			)}
		</div>
	);
};

const Collections = ({ pageId }: { pageId: string }) => {
	const token = hasCookie("token") ? getCookie("token") : "";
	const {
		data: collections,
		mutate,
		isLoading,
	} = useSWR<any>(token ? ["/v1/members/collections/"] : null, swrFetcher);

	const toggleCollection = async (id: string) => {
		try {
			await fetchWrapper(`/v1/members/collections/${id}/add`, {
				method: "POST",
			});
		} catch (error) {
			toast({
				title: "Error!",
				description: error.message,
				action: (
					<ToastAction altText="Try again" onClick={() => toggleCollection(id)}>
						Try again
					</ToastAction>
				),
				variant: "error",
			});
		} finally {
			mutate();
		}
	};

	if (isLoading)
		return (
			<div className="flex w-full items-center justify-center gap-2">
				<ol className="flex w-full flex-col gap-3">
					<li className="flex w-full items-center justify-between gap-2">
						<Skeleton className="h-6 w-2/3 " />
						<Skeleton className="size-6 rounded-full" />
					</li>
					<li className="flex w-full items-center justify-between gap-2">
						<Skeleton className="h-6 w-2/3 " />
						<Skeleton className="size-6 rounded-full" />
					</li>
					<li className="flex w-full items-center justify-between gap-2">
						<Skeleton className="h-6 w-2/3 " />
						<Skeleton className="size-6 rounded-full" />
					</li>
				</ol>
			</div>
		);

	if (!collections)
		return (
			<div className="flex min-h-[200px] items-center justify-center gap-2">
				<div className="text-center">
					You don&apos;t have any collections yet. <br />
					Add one by clicking &quot;
					<AddCollectionDialog>
						<Button
							variant="text"
							className="group inline p-0"
							aria-label="New collection"
							title="New collection"
						>
							New collection
						</Button>
					</AddCollectionDialog>
					&quot;.
				</div>
			</div>
		);

	return (
		<div className="flex flex-col gap-0.5">
			{collections?.map((collection: Collection) => (
				<CollectionItem
					key={collection.id}
					collection={collection}
					pageId={pageId}
					mutate={mutate}
				/>
			))}
		</div>
	);
};

const UnsavePageDialog = ({
	children,
	unsaveAction,
	unsaveLoading,
}: { unsaveAction: () => void; unsaveLoading: boolean } & React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle className="text-lg">Unsave page</DialogTitle>
					<DialogDescription className="text-base">
						Unsaving this post will also remove it from any collections.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<DialogClose>
						<Button type="button" variant="outline">
							Cancel
						</Button>
					</DialogClose>
					<Button
						type="submit"
						disabled={unsaveLoading}
						aria-disabled={unsaveLoading}
						variant="destructive"
						onClick={() => unsaveAction()}
					>
						{unsaveLoading && <Spinner color="gray" size="4" />}
						Unsave
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

interface SaveButtonProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof buttonVariants> {
	page?: Page;
}

const SaveButton = ({
	page,
	className,
	variant = "secondary",
	size,
	children,
}: SaveButtonProps) => {
	const token = hasCookie("token") ? getCookie("token") : "";
	const { user } = useUser();
	const {
		data: savedPages,
		isLoading,
		mutate,
	} = useSWR<SavedPage[]>(token ? ["/v1/members/following"] : null, swrFetcher);
	const router = useRouter();
	const [isSaved, setIsSaved] = React.useState(false);
	const [loading, setLoading] = React.useState<{
		save: boolean;
		sendNotification: boolean;
	}>({
		save: false,
		sendNotification: false,
	});
	const [pageId, setPageId] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [sendNotification, setSendNotification] = React.useState(true);
	const dialogRef = React.useRef<HTMLDivElement>(null);
	const closeRef = React.useRef<HTMLButtonElement>(null);
	const { name, url, slug, group, type } = page || {};

	React.useEffect(() => {
		if (savedPages) {
			const path = url.includes("http") ? new URL(url)?.pathname : url;
			const toggledPage = savedPages.find((savedPage: SavedPage) => savedPage.url.includes(path));
			setIsSaved(!!toggledPage);
			setSendNotification(toggledPage?.send_notification);
			setPageId(toggledPage?.id);
		}
	}, [savedPages, url]);

	const handleUnsavePage = async () => {
		setLoading({ ...loading, save: true });
		await fetchWrapper(`/v1/members/following/${pageId}`, {
			method: "DELETE",
		})
			.then(() => {
				toast({
					title: "Page Unsaved!",
				});
				setOpen(false);
			})
			.catch((error: any) => {
				toast({
					title: "Error!",
					description: error.message,
					action: <ToastAction altText="Try again">Try again</ToastAction>,
					variant: "error",
				});
			})
			.finally(() => {
				setLoading({ ...loading, save: false });
				mutate();
				revalidateByTag("saved-pages");
			});
	};

	const handleSavePage = async () => {
		if (!token || !user) {
			router.push("/signup/?source=save_page");
			return;
		}
		if (isLoading) return;

		setLoading({ ...loading, save: true });
		await fetchWrapper(`/v1/${group || type}/${slug}/follow`, {
			method: "POST",
			body: JSON.stringify({
				send_notification: sendNotification,
			}),
		})
			.then((res: { data: { id: string } | string; success: boolean }) => {
				if (!res.success) {
					toast({
						title: "Error!",
						description: res.data as string,
						action: <ToastAction altText="Try again">Try again</ToastAction>,
						variant: "error",
					});
				}
			})
			.catch((error: any) => {
				toast({
					title: "Error!",
					description: error.message,
					action: <ToastAction altText="Try again">Try again</ToastAction>,
					variant: "error",
				});
			})
			.finally(() => {
				setLoading({ ...loading, save: false });
				mutate();
				revalidateByTag("saved-pages");
			});
	};

	const handleGetUpdates = async ({ sendNotification }: { sendNotification: boolean }) => {
		const payload = {
			send_notification: !sendNotification,
		};

		setLoading({ ...loading, sendNotification: true });
		try {
			await fetchWrapper(`/v1/members/following/${pageId}`, {
				method: "PATCH",
				body: JSON.stringify(payload),
			});
		} catch (error) {
			toast({
				title: "Error!",
				description: error.message,
				action: <ToastAction altText="Try again">Try again</ToastAction>,
				variant: "error",
			});
		} finally {
			setSendNotification(!sendNotification);
			setLoading({ ...loading, sendNotification: false });
			revalidateByTag("saved-pages");
		}
	};

	const buttonClasses = cn(
		`shrink-0 -tracking-2 text-sm lg:text-base px-4 py-2 font-inter`,
		className
	);
	const iconClasses = `w-4 h-4 lg:w-5 lg:h-5`;

	return (
		<Sheet
			open={open}
			onOpenChange={(e) => {
				setOpen(e);
			}}
		>
			<ClientOnly>
				<SheetTrigger asChild>
					{children ||
						(isSaved ? (
							<Button
								variant={variant}
								size={size}
								className={buttonClasses}
								disabled={loading.save || isLoading}
							>
								<HiBookmark className={iconClasses} />
								<span className="hidden md:block">Page saved</span>
								{(loading.save || isLoading) && <Spinner color="gray" size="4" />}
							</Button>
						) : (
							<Button
								variant={variant}
								size={size}
								className={buttonClasses}
								onClick={() => handleSavePage()}
								disabled={loading.save || isLoading}
							>
								<BookmarkIcon className={iconClasses} />
								<span className="hidden md:block">Save page</span>
								{(loading.save || isLoading) && <Spinner color="gray" size="4" />}
							</Button>
						))}
				</SheetTrigger>
			</ClientOnly>
			<SheetContent
				side={"right"}
				className="overflow-x-hidden pt-0"
				onClick={(e) => e.stopPropagation()}
				ref={dialogRef}
			>
				<SheetHeader className="sticky top-0 mx-auto flex flex-row items-center justify-between space-y-0 bg-white pt-6">
					<SheetTitle>Saved page - {parse(name)}</SheetTitle>
					<SheetClose ref={closeRef} className="flex rotate-45 items-center">
						<PlusCircle />
					</SheetClose>
					<VisuallyHidden>
						<SheetDescription>Saved page - {parse(name)}</SheetDescription>
					</VisuallyHidden>
				</SheetHeader>
				<div className="py-2">
					<div className="mb-10 space-y-4">
						<div className="flex items-center justify-between gap-2">
							<h2 className="text-base font-normal">{isSaved ? "Saved" : "Save"}</h2>
							<UnsavePageDialog unsaveAction={handleUnsavePage} unsaveLoading={loading.save}>
								<Button
									variant="ghost"
									className="group p-0"
									aria-label="Unsave page"
									title="Unsave page"
								>
									{loading.save ? (
										<Spinner color="gray" size="5" />
									) : (
										<Bookmark
											size={24}
											className="fill-primary stroke-primary group-hover:fill-examine-purple-400 group-hover:stroke-examine-purple-400 group-active:fill-examine-purple-400 group-active:stroke-examine-purple-400"
										/>
									)}
								</Button>
							</UnsavePageDialog>
						</div>
						<div className="flex items-center justify-between gap-2">
							<Tooltip placement="bottom">
								<TooltipTrigger asChild>
									<h2 className="flex items-center gap-1 text-base font-normal">
										Get update notifications
										<Info
											size={14}
											className="cursor-pointer text-gray-900 hover:text-gray-600 active:text-gray-600"
										/>
									</h2>
								</TooltipTrigger>
								<TooltipContent variant="regular">
									Toggle this setting to get an email whenever this page is updated with new content
									or when a new study related to {name} is summarized.
								</TooltipContent>
							</Tooltip>
							{loading.sendNotification ? (
								<Spinner color="gray" size="5" />
							) : (
								<Switch
									id="get-updates"
									value={sendNotification ? "off" : "on"}
									checked={sendNotification}
									thumbClassName="w-4 h-4"
									className="h-[20px] w-[40px] shadow-lg"
									onCheckedChange={() => {
										handleGetUpdates({ sendNotification });
									}}
								/>
							)}
						</div>
					</div>
					<div className="mb-4 flex items-center justify-between gap-2">
						<h2 className="text-base font-medium lg:text-xl">Collections</h2>
						<AddCollectionDialog>
							<Button
								variant="text"
								className="group p-0"
								aria-label="New collection"
								title="New collection"
							>
								New collection
							</Button>
						</AddCollectionDialog>
					</div>
					<Collections pageId={pageId} />
				</div>
			</SheetContent>
		</Sheet>
	);
};

interface SavedPageProps {
	page?: SavedPage & { id: string; updates: any[] };
	handleUnsavePage?: (id: string) => void;
}

const SavedListItem = ({ page }: SavedPageProps) => {
	const [showUpdate, setShowUpdate] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const lastUpdate = page?.updates[page.updates.length - 1];

	const toggleUpdate = () => {
		setShowUpdate(!showUpdate);
	};

	const handleUnsavePage = async (id: string) => {
		setLoading(true);
		await fetchWrapper(`/v1/members/following/${id}`, {
			method: "DELETE",
		})
			.then(() => {
				toast({
					title: "Page Unsaved!",
				});
			})
			.catch((error: any) => {
				toast({
					title: "Error!",
					description: error.message,
					action: <ToastAction altText="Try again">Try again</ToastAction>,
					variant: "error",
				});
			})
			.finally(() => {
				setLoading(false);
				location.reload();
			});
	};

	return (
		<li>
			<div className="grid grid-flow-col grid-cols-2 items-center justify-between sm:flex">
				<Link href={page.url} className="mr-auto text-primary underline">
					{page.name}
				</Link>

				{lastUpdate && (
					<>
						<p className="col-span-2 row-start-2 flex gap-2 text-13 font-medium sm:ml-auto sm:mr-3 sm:text-sm md:flex-row md:text-base">
							<span>Last updated:</span>
							{new Date(lastUpdate.date).toLocaleDateString("en-US", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})}
						</p>
						<Button
							className="float-right mr-3 hidden cursor-pointer select-none rounded px-3 py-1.5 text-primary hover:bg-gray-100 lg:block"
							variant="text"
							onClick={toggleUpdate}
						>
							{showUpdate ? "Hide" : "Show"}
						</Button>
					</>
				)}

				<Button
					onClick={() => handleUnsavePage(page.id)}
					variant="text"
					className={lastUpdate ? "row-span-2" : ""}
				>
					{loading && <Spinner color="white" size="5" />}
					Unsave
					<XIcon className="h-6 w-6" />
				</Button>
			</div>

			{lastUpdate && showUpdate && (
				<div className="hidden lg:block">
					<Update {...lastUpdate} />
				</div>
			)}
		</li>
	);
};

const SavedListItemV2 = ({
	page,
	searchAction,
}: { searchAction?: ({}) => void } & SavedPageProps) => {
	const [showUpdate, setShowUpdate] = React.useState(false);
	const lastUpdate = page?.updates[page.updates.length - 1];

	const toggleUpdate = () => {
		setShowUpdate(!showUpdate);
	};

	return (
		<>
			<SaveButton page={page}>
				<TableRow className="cursor-pointer appearance-none data-[state=open]:bg-examine-purple-100">
					<TableCell className="w-2/5">
						<Link href={page.url} className="link underline">
							{parse(page.name)}
						</Link>

						{lastUpdate && (
							<div
								className="group flex flex-wrap items-center gap-1 text-sm"
								onClick={(e) => {
									e.stopPropagation();
									toggleUpdate();
								}}
							>
								<span>
									Updated:{" "}
									{new Date(lastUpdate.date).toLocaleDateString("en-US", {
										year: "numeric",
										month: "short",
										day: "numeric",
									})}
								</span>
								<ToggleButton
									state={showUpdate ? "off" : "on"}
									iconSize={16}
									title={showUpdate ? "Hide updates" : "Show updates"}
								/>
							</div>
						)}
					</TableCell>
					<TableCell className="w-1/4">{page.type}</TableCell>
					<TableCell className="w-[10%] min-w-7">
						<div className="flex items-center justify-center">
							{page.send_notification ? (
								<Bell className="size-5 stroke-primary" />
							) : (
								<BellOff className="size-5 stroke-primary" />
							)}
						</div>
					</TableCell>
					<TableCell className="w-1/3">
						<div className="flex flex-wrap gap-1">
							{page.collections.map((collection) => (
								<Badge
									size="xs"
									className="inline whitespace-nowrap"
									key={collection.id}
									onClick={(e) => {
										e.stopPropagation();
										searchAction({ collection: collection.id });
									}}
								>
									{collection.name}
								</Badge>
							))}
						</div>
					</TableCell>
				</TableRow>
			</SaveButton>
			{lastUpdate && showUpdate && (
				<TableRow>
					<TableCell colSpan={5} className="h-20">
						<div className="hidden lg:block">
							<Update {...lastUpdate} />
						</div>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

const SavedListV2 = ({ pages, collections }: { pages: SavedPage[]; collections: Collection[] }) => {
	const searchParams = useSearchParams();
	const [filteredPages, setFilteredPages] = React.useState(pages);
	const [search, setSearch] = React.useState({
		name: searchParams?.get("q") || "",
		collection: searchParams?.get("collection") || "",
		updates: searchParams?.get("updates") || "",
	});

	const filterPageData = (pages: SavedPage[]) => {
		const { name, collection, updates } = search ?? {};

		return pages
			.filter(
				(page) =>
					page.name.toLowerCase().includes(name.toLowerCase()) ||
					page.collections.some((c) => c.name.toLowerCase().includes(name.toLowerCase()))
			)
			.filter((page) =>
				updates === "all" || !updates ? true : page.send_notification === (updates === "yes")
			)
			.filter((page) =>
				collection === "all" || !collection
					? true
					: page.collections.some((c) => c.id === collection)
			);
	};

	const handleSearch = useDebouncedCallback(
		({ name, collection, updates }: { name: string; collection: string; updates: string }) => {
			const params = new URLSearchParams(searchParams);
			if (name) {
				params.set("name", name);
			} else {
				params.delete("name");
			}

			if (collection) {
				params.set("collection", collection);
			} else {
				params.delete("collection");
			}

			if (updates) {
				params.set("updates", updates);
			} else {
				params.delete("updates");
			}

			window.history.replaceState(null, "", `?${params.toString()}`);
			setFilteredPages(filterPageData(pages));
		},
		300
	);

	const getCollectionName = (collectionId: string) => {
		const collection = collections.find((c) => c.id === collectionId);
		return collection?.name || "All";
	};

	React.useEffect(() => {
		const { name, collection, updates } = search ?? {};

		if (name || collection || updates) {
			setFilteredPages(filterPageData(pages));

			return;
		}

		setFilteredPages(pages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pages, search]);

	React.useEffect(() => {
		handleSearch(search);
	}, [handleSearch, search]);

	return (
		<article>
			<div className="mb-4 flex flex-wrap items-center gap-2">
				<Input
					type="search"
					name="search"
					placeholder="Search"
					autoComplete="off"
					onChange={(e) => {
						setSearch({ ...search, name: e.target.value });
					}}
					defaultValue={searchParams?.get("q") || ""}
					className="w-full lg:w-2/5"
				/>
				<Select
					onValueChange={(value) => setSearch({ ...search, collection: value })}
					defaultValue={searchParams?.get("collection") || ""}
					value={search.collection}
				>
					<SelectTrigger className="w-full text-black lg:w-1/5">
						<div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
							<SelectValue placeholder="Collections" />
						</div>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="all">Collections (All)</SelectItem>
						{collections.map((collection) => (
							<SelectItem value={collection.id} key={collection.id}>
								{collection.name}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				<Select
					onValueChange={(value) => setSearch({ ...search, updates: value })}
					defaultValue={searchParams?.get("updates") || ""}
				>
					<SelectTrigger className="w-full text-black lg:w-1/5">
						<SelectValue placeholder="Get Updates?" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="all">Get Updates? (All)</SelectItem>
						<SelectItem value="yes">Yes</SelectItem>
						<SelectItem value="no">No</SelectItem>
					</SelectContent>
				</Select>
			</div>
			{(search.name ||
				(search.collection !== "all" && search.collection) ||
				(search.updates !== "all" && search.updates)) && (
				<div className="min-w-min space-y-3 rounded border border-sky-200 bg-sky-100 p-4">
					<div className="flex flex-row-reverse items-center justify-between">
						<Button
							variant="text"
							size="sm"
							className="col-span-2"
							onClick={() =>
								setSearch({
									name: "",
									collection: "",
									updates: "",
								})
							}
						>
							<XCircle size="16" />
							Clear Filters
						</Button>
						<h6 className="text-sm font-semibold">Filtered by:</h6>
					</div>
					<div className="grid grid-cols-1 gap-2 text-sm sm:grid-cols-3">
						{search.name ? (
							<div>
								Page/Collection Name: &quot;<span className="font-semibold">{search.name}</span>
								&quot;
							</div>
						) : (
							""
						)}
						{search.collection !== "all" && search.collection ? (
							<div>
								Collection:{" "}
								<Badge size="xs" className="inline whitespace-nowrap">
									{getCollectionName(search.collection)}
								</Badge>
							</div>
						) : (
							""
						)}
						{search.updates !== "all" && search.updates ? (
							<div>
								Updates: <span className="font-semibold capitalize">{search.updates}</span>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			)}
			<Table className="my-6 sm:table-fixed">
				<TableHeader>
					<TableRow>
						<TableHead className="w-2/5">Page</TableHead>
						<TableHead className="w-1/4">Type</TableHead>
						<TableHead className="w-[10%] min-w-7">Get updates</TableHead>
						<TableHead className="w-1/3">Collections</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{filteredPages.length ? (
						filteredPages.map((page: SavedPage) => (
							<SavedListItemV2
								page={page}
								key={page.id}
								searchAction={(e) => setSearch({ ...search, ...e })}
							/>
						))
					) : (
						<TableCell colSpan={4} className="h-24 text-center">
							No saved pages found.
						</TableCell>
					)}
				</TableBody>
			</Table>
		</article>
	);
};

export { SaveButton, SavedListItem, SavedListV2 };
