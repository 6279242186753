import { cva, type VariantProps } from "class-variance-authority";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

import { cn } from "@/lib/utils";

const linkVariants = cva("cursor-pointer hover:underline", {
	variants: {
		variant: {
			default: "text-examine-purple-400",
			white: "text-white underline",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

const Link: React.FC<
	NextLinkProps & {
		children?: React.ReactNode;
		className?: string;
	} & VariantProps<typeof linkVariants>
> = ({ className, variant, ...props }) => {
	return <NextLink className={cn(linkVariants({ variant }), className)} {...props} />;
};

export default Link;
